import React from 'react';
import Modal from 'react-modal';
import './DonateModal.css'; // Import the CSS file

Modal.setAppElement('#root'); // Set the root element for accessibility

const DonateModal = ({ isOpen, onRequestClose, address, qrCodeImage, title }) => {

  const copyToClipboard = () => {
    navigator.clipboard.writeText(address);
    alert(`${title} address copied to clipboard!`);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={`Donate ${title}`}
      className="modal"
      overlayClassName="overlay"
    >
      <div className="modal-content">
        <h2>Donate {title}</h2>
        <img src={qrCodeImage} alt={`${title} Address QR Code`} className="qr-code" />
        <p>Scan the QR code or copy the address below to donate {title}:</p>
        <div className="address-container">
          <p className="eth-address">{address}</p>
          <button onClick={copyToClipboard} className="copy-button">Copy</button>
        </div>
        <button onClick={onRequestClose} className="close-button">Close</button>
      </div>
    </Modal>
  );
};

export default DonateModal;
