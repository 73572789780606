import React, { useState, useEffect } from 'react';
import { Body, Button, Container, Header, Image, Link } from "./index";
import axios from 'axios';
import './BtcUsdtPrice.css';

const germans_bitcoins = 49858
const germans_average_sell_price = 58243.85
const formattedSoldPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(germans_average_sell_price);

const BtcUsdtPrice = () => {
  const [price, setPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd'
        );
        setPrice(response.data.bitcoin.usd);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchPrice();

    const interval = setInterval(fetchPrice, 60000); // Fetch every minute

    return () => clearInterval(interval);
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const difference = germans_bitcoins * (price - germans_average_sell_price);
  const formattedDifference = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(difference);

  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);

  const differenceStyle = {
    color: difference > 0 ? 'red' : 'green',
    fontSize: '2.5em', // Adjust the font size as needed
    padding: 0,
  };

  return (
    <div  className="centered">
      <Link href="https://www.medienservice.sachsen.de/medien/news/1077662">Emergency sale of almost 50,000 Bitcoins completed</Link>
      <div>The German government sold {germans_bitcoins} bitcoins with Approximately Average Price of {formattedSoldPrice} per Bitcoin</div>
      <div>Current Bitcoin Price is {formattedPrice}</div>
      <div><p className="dark-red-text">How Much They Lost So far?</p></div>
      <div><h1 style={differenceStyle}>{formattedDifference}</h1></div>
    </div>
  );
};

export default BtcUsdtPrice;
