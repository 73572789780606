import { useQuery } from "@apollo/client";
import { Contract } from "@ethersproject/contracts";
import { shortenAddress, useCall, useEthers, useLookupAddress } from "@usedapp/core";
import React, { useEffect, useState } from "react";

import { Body, Button, Container, Header, Image, Link } from "./components";
import logo from "./bitcoinLogo.png";
//import QR_ETH from "./qr_eth.jpg";

import { addresses, abis } from "@my-app/contracts";
import GET_TRANSFERS from "./graphql/subgraph";

import BtcUsdtPrice from './components/BtcUsdtPrice';
import DonateModal from './components/DonateModal';
import ethQRCode from './assets/qr_eth.jpg';
import btcQRCode from './assets/qr_btc.jpg';

function WalletButton() {
  const [rendered, setRendered] = useState("");

  const { ens } = useLookupAddress();
  const { account, activateBrowserWallet, deactivate, error } = useEthers();

  useEffect(() => {
    if (ens) {
      setRendered(ens);
    } else if (account) {
      setRendered(shortenAddress(account));
    } else {
      setRendered("");
    }
  }, [account, ens, setRendered]);

  useEffect(() => {
    if (error) {
      console.error("Error while connecting wallet:", error.message);
    }
  }, [error]);

  return (
    <Button
      onClick={() => {
        if (!account) {
          activateBrowserWallet();
        } else {
          deactivate();
        }
      }}
    >
      {rendered === "" && "Connect Wallet"}
      {rendered !== "" && rendered}
    </Button>
  );
}

function App() {
  const [ethModalIsOpen, setEthModalIsOpen] = useState(false);
  const [btcModalIsOpen, setBtcModalIsOpen] = useState(false);

  const openEthModal = () => {
    setEthModalIsOpen(true);
  };

  const closeEthModal = () => {
    setEthModalIsOpen(false);
  };

  const openBtcModal = () => {
    setBtcModalIsOpen(true);
  };

  const closeBtcModal = () => {
    setBtcModalIsOpen(false);
  };

  // Read more about useDapp on https://usedapp.io/
  const { error: contractCallError, value: tokenBalance } =
    useCall({
       contract: new Contract(addresses.ceaErc20, abis.erc20),
       method: "balanceOf",
       args: ["0x3f8CB69d9c0ED01923F11c829BaE4D9a4CB6c82C"],
    }) ?? {};

  const { loading, error: subgraphQueryError, data } = useQuery(GET_TRANSFERS);

  useEffect(() => {
    if (subgraphQueryError) {
      console.error("Error while querying subgraph:", subgraphQueryError.message);
      return;
    }
    if (!loading && data && data.transfers) {
      console.log({ transfers: data.transfers });
    }
  }, [loading, subgraphQueryError, data]);

  return (
    <Container>
      {
        /*
      <Header>
        <WalletButton />
      </Header>
    */
      }
      <Body>
        <Image src={logo} alt="bitcoin-logo" />
        <BtcUsdtPrice />
        {
        /*
        <header className="App-header">
          
        </header> 
        */
        }

        {
            /*
                    <Link className="inline-link" href="https://www.blockchain.com/explorer/addresses/btc/bc1qxtq8hsxzfnjw4h8nw45ckfjjnr7zn36qaprkkn" alt="bc1qxtq8hsxzfnjw4h8nw45ckfjjnr7zn36qaprkkn">Donate BTC (bc1qxtq8hsxzfnjw4h8nw45ckfjjnr7zn36qaprkkn)</Link>
                    <Link className="inline-link" href="https://etherscan.io/address/0x3EaD1817a6ff056c887721800E366B67b289B96B" alt="0x3EaD1817a6ff056c887721800E366B67b289B96B">Donate ETH (0x3EaD1817a6ff056c887721800E366B67b289B96B)</Link>

            */
        }
        
        <div className="donate-buttons">
          <button onClick={openEthModal} className="donate-button eth-button">Donate ETH</button>
          <button onClick={openBtcModal} className="donate-button btc-button">Donate BTC</button>
        </div>
        
        <DonateModal 
          isOpen={ethModalIsOpen} 
          onRequestClose={closeEthModal} 
          address="0x3EaD1817a6ff056c887721800E366B67b289B96B" 
          qrCodeImage={ethQRCode} 
          title="ETH" 
        />
        <DonateModal 
          isOpen={btcModalIsOpen} 
          onRequestClose={closeBtcModal} 
          address="bc1qxtq8hsxzfnjw4h8nw45ckfjjnr7zn36qaprkkn" 
          qrCodeImage={btcQRCode} 
          title="BTC" 
        />
        
        <Link href="https://www.sotwe.com/vegoverse">Follow Me on Twitter X</Link>
        
      </Body>
    </Container>
  );
}

export default App;
